import React, { useContext } from 'react'
import cn from 'classnames'

// TODO:: JSON needs to come from somewhere else
import pages from '@/network/data/home_slides.json'

import PageScrollProvider from '@/components/ui/PageScroll/PageScrollProvider'
import PageScrollIndicator from '@/components/ui/PageScroll/Indicator'
import { SiteContext } from 'providers'
import NavigationProvider from '@/components/ui/Navigation'
import MegaMenuProvider from '@/components/ui/Navigation/MegaMenu'
import Announcements from '@/components/ui/Announcements'
import Header from '@/components/shared/Header'
import SocialBar from '@/components/shared/SocialBar'
import { PulsingCircle, Typography } from '@/components/ui'

import { getFooterSocials } from '@/utils/Social'
import FooterDrawer from './FooterDrawer/FooterDrawer'
import Footer from '../../Footer'

import s from './styles.module.css'
import { LayoutProps } from './types'

/**
 * Home layoutc
 * @param props 
 * @returns 
 */
const HomeLayout = (props: LayoutProps) => {
  const { children } = props

  const { announcements } = useContext(SiteContext)

  const links = getFooterSocials()

  return (
    <>
      <PageScrollProvider pages={pages}>
        <NavigationProvider>
          <MegaMenuProvider>
            <Header
              accountMenuItemsClassName={s.bg_opacity}
              className={s.bg_opacity_before}
              megaMenuClassName={s.bg_opacity_before} />
          </MegaMenuProvider>
        </NavigationProvider>

        <main>
          {children}
        </main>

        <Announcements
          panelClassName={s.bg_opacity_before}
          expandButtonClassName={s.bg_opacity}
          announcements={announcements} />

        <FooterDrawer
          expandButtonClassName={s.bg_opacity}
          previewPanelClassName={s.bg_opacity}
          closePanelClassName={s.bg_opacity}
          previewBar={(
            <div className={s.footer_preview}>
              <PageScrollIndicator />
              <div className={s.join_community}>
                <div className={s.heading} >
                  <div className={cn(s.bg_opacity, s.heading_background)} />
                  <div className={s.heading_text}>
                    <PulsingCircle />
                    <Typography
                      uppercase>
                      <Typography
                        className={s.heading_highlight}
                        uppercase>
                        Join
                      </Typography> our community
                    </Typography>
                  </div>
                </div>
                <SocialBar
                  iconClassName={s.social_icon}
                  links={links} />
              </div>
            </div>
          )}>
          <Footer className={s.bg_opacity} />
        </FooterDrawer>
      </PageScrollProvider>
    </>
  )
}

export default HomeLayout