import React, { createContext, useCallback, useContext, useState, useMemo } from 'react'
import { useSession } from 'next-auth/react'
import { toast } from 'react-toastify'

import { CustomTagAssignmentType, CustomTagType } from 'types'
import { PlayerHttpClient } from '@/network/httpClients'
import t from 'helpers/translation/getTranslation'

import { useAuth } from '../AuthenticationProvider'
import { CustomTagManagerProviderProps, CustomTagManager } from './types'

const CustomTagManagerContext = createContext<CustomTagManager | undefined>(undefined)

const CustomTagManagerProvider = (props: CustomTagManagerProviderProps) => {
  const { children, unassignedCustomTags = [], assignedCustomTags = [], profileCustomTag: defaultProfileCustomTag } = props
  const { user, setDisplayName } = useAuth()
  const { data: session } = useSession()

  const [assigned, setAssigned] = useState<CustomTagAssignmentType[]>(assignedCustomTags)
  const [unassigned, setUnassigned] = useState<CustomTagType[]>(unassignedCustomTags)
  const [profileCustomTag, setProfileCustomTag] = useState<CustomTagAssignmentType | null>(defaultProfileCustomTag)

  const updateCustomTags = useCallback((customTagAssignment: CustomTagAssignmentType) => {
    // Rather than call another 2 APIs to update the assigned and unassigned list, let's just do in JS

    // Deal with assigned first, either add assignment to list or replace existing one.
    const exists = assigned.find(assignment => assignment.gamePart.id === customTagAssignment.gamePart.id)
    let currentAssignedCustomTag: CustomTagAssignmentType | undefined

    if (!exists)
      setAssigned([...assigned, customTagAssignment])
    else {
      const update = assigned.reduce((acc, curr) => {
        if (curr.gamePart.id === customTagAssignment.gamePart.id) {
          // This will be used to push the current assignment into the unassigned list
          currentAssignedCustomTag = curr

          return [...acc, customTagAssignment]
        }

        return [...acc, curr]
      }, [] as CustomTagAssignmentType[])

      setAssigned(update)
    }

    // Also update the profile tag state
    setProfileCustomTag(customTagAssignment)
    setDisplayName(customTagAssignment.customTag.name)

    // For the unassigned list, remove the current assigned item and add the assignment that got replaced.
    const removed = unassigned.filter(customTag => customTag.id !== customTagAssignment.customTag.id)

    if (currentAssignedCustomTag)
      setUnassigned([...removed, currentAssignedCustomTag.customTag])
    else
      setUnassigned(removed)
  }, [assigned, unassigned, setDisplayName])

  const removeProfileCustomTag = useCallback(async () => {
    if (!session?.accessToken || !user?.sub || !profileCustomTag) {
      return
    }

    const { response } = await PlayerHttpClient.setAccessToken(session?.accessToken).RemoveCustomTagAssignment(user?.sub, profileCustomTag?.id)

    if (response.ok) {
      setProfileCustomTag(null)
      // Reset display name
      setDisplayName(`${user.username}#${user.discriminator}`)
      // Remove the tag from assigned list
      const updated = assigned.filter((assignment) => assignment.gamePart.id !== profileCustomTag.gamePart.id)
      setAssigned(updated)
      // Push the tag being remove into unassigned.
      setUnassigned([...unassigned, profileCustomTag.customTag])

    }
    else
      toast.error(t("pages.settings.customTagUnassignedToProfile"))
  }, [profileCustomTag, session, user, assigned, setDisplayName, unassigned])

  const value = useMemo(
    () => ({
      assigned,
      unassigned,
      profileCustomTag,
      updateCustomTags,
      removeProfileCustomTag
    } as CustomTagManager),
    [
      assigned,
      unassigned,
      profileCustomTag,
      updateCustomTags,
      removeProfileCustomTag
    ]
  );

  return <CustomTagManagerContext.Provider value={value}>{children}</CustomTagManagerContext.Provider>
}

const useCustomTagManager = () => {
  const context = useContext(CustomTagManagerContext)
  if (!context) {
    throw new Error('useCustomTagManagerContext must be used within an < CustomTagManagerProvider />')
  }

  return context
}

export { useCustomTagManager, CustomTagManagerContext }
export default CustomTagManagerProvider