
import React from 'react'
import { motion } from 'framer-motion'

import Button from '@/components/ui/Button'
import VisuallyHidden from '@/components/ui/VisuallyHidden'

import { usePageScroll } from '../PageScrollProvider'

import s from './styles.module.css'

const PageScrollIndicator = () => {
  const { currentPage, pages, scrollToPage } = usePageScroll()

  return (

    <div className={s.indicators}>
      {
        pages.map((page) => {
          const { headline1, headline2, id } = page
          const isActive = currentPage === id

          return (
            <Button
              key={id}
              filled
              onClick={() => scrollToPage(id)}
              variant="secondary"
              className={s.indicator}>
              {
                isActive &&
                <motion.div layoutId="page_scroll_indicator" className={s.active_indicator} />
              }
              <VisuallyHidden>Jump to: {headline1} {headline2}</VisuallyHidden>
            </Button>
          )
        })
      }

    </div>
  )
}

export default PageScrollIndicator