import React, { useState, useEffect, useRef } from 'react'
import { useMediaQuery } from 'react-responsive'
import cn from 'classnames'

import VisuallyHidden from '@/components/ui/VisuallyHidden'
import { DoubleArrow } from '@/components/ui/Icon'
import Drawer from '@/components/ui/Drawer'
import { useKeyPress, useTailwindMediaQuery, useOnClickOutside } from '@/hooks'

import s from './styles.module.css'
import { FooterDrawerProps } from './types'

/**
 * Animated footer drawer
 * @param props 
 * @returns 
 */
const FooterDrawer = (props: FooterDrawerProps) => {
  const { previewBar, children, expandButtonClassName, previewPanelClassName, closePanelClassName } = props

  const ref = useRef<HTMLDivElement>(null)
  const [isVisible, setIsVisible] = useState<boolean>(false)

  const isLandscape = useMediaQuery({ maxHeight: 850 });
  const isMobile = !useTailwindMediaQuery('md') || isLandscape
  const expandButtonClasses = cn(s.open_drawer, expandButtonClassName)
  const previewPanelClasses = cn(s.preview, previewPanelClassName)
  const closePanelClasses = cn(s.close_drawer, closePanelClassName)

  // Workaround for SSR
  useEffect(() => {
    setIsVisible(!!isMobile);
  }, [isMobile]);

  const onClick = () => { setIsVisible(!isVisible) }

  // On click off close footer, disable if mobile
  useOnClickOutside(ref, () => !isMobile && setIsVisible(false))

  // Close on esc press
  const escape = useKeyPress("Escape")

  // On esc press close, disable if mobile
  useEffect(() => {
    if (escape && !isMobile) {
      setIsVisible(false)
    }
  }, [escape, isMobile])

  return (
    <>
      {!isVisible &&
        <div className={previewPanelClasses}>
          <button onClick={onClick} type='button' className={expandButtonClasses}>
            <DoubleArrow rotation="180" />
            <VisuallyHidden>Open Footer Menu</VisuallyHidden>
          </button>
          {
            previewBar &&
            <div className={s.preview_bar}>
              {previewBar}
            </div>
          }
        </div>
      }
      <Drawer ref={ref} isVisible={isVisible} className={s.drawer}>
        <div className={closePanelClasses}>
          <button onClick={onClick} type='button' className={s.close_drawer_btn}>
            <DoubleArrow />
            <VisuallyHidden>Close Footer Menu</VisuallyHidden>
          </button>
        </div>
        {children}
      </Drawer>
    </>
  )
}

export default FooterDrawer