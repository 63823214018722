import React from 'react'
import NavigationProvider from '@/components/ui/Navigation'
import MegaMenuProvider from '@/components/ui/Navigation/MegaMenu'
import Header from '@/components/shared/Header'
import Footer from '../../Footer'

import s from './styles.module.css'
import { LayoutProps } from './types'

/**
 * Landing page layouts
 * @param props 
 * @returns 
 */
const LandingLayout = (props: LayoutProps) => {
  const { children } = props

  return (
    <>
      <div className={s.page}>
        <NavigationProvider>
          <MegaMenuProvider>
            <Header />
          </MegaMenuProvider>
        </NavigationProvider>

        <main>
          {children}
        </main>

      </div>

      <Footer className={s.footer} />
    </>
  )
}

export default LandingLayout