import React, { createContext, useContext, useState, useEffect, useCallback, useMemo } from 'react'
import { destroyCookie, parseCookies, setCookie } from 'nookies'
import { loadStripe, Stripe } from '@stripe/stripe-js'
import { toast } from 'react-toastify'

import { MarketplaceCustomTagPaymentOrderCreateRequestType, MarketplaceCustomTagPaymentOrderType } from 'types'
import { CustomTagHttpClient } from '@/network/httpClients'
import t from 'helpers/translation/getTranslation'
import isEmpty from 'helpers/isEmpty'

import { StripePaymentProviderProps, StripePaymentContextType } from './types'

const StripePaymentContext = createContext<StripePaymentContextType | undefined>(undefined)

const COOKIE_NAME = "cornucopias_marketplace_intent"

const StripePaymentProvider = (props: StripePaymentProviderProps) => {
  const { children } = props
  const stripePKey = process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY as string
  const [clientSecret, setClientSecret] = useState<string | undefined>(undefined)
  const [loading, setIsLoading] = useState<boolean>(false)
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>(new Promise(() => null))

  const initStripe = useCallback(async () => {
    // Make sure to call `loadStripe` once to avoid recreating the `Stripe` object on every render.
    if (stripePKey)
      setStripePromise(loadStripe(stripePKey))
  }, [stripePKey])

  useEffect(() => {
    initStripe()
  }, [])

  const createCustomTagPayment = useCallback(async (accessToken: string, playerSub: string, payload: MarketplaceCustomTagPaymentOrderType) => {
    const cookies = parseCookies(null)

    if (clientSecret) return

    const paymentPayload = {
      paymentOrderId: payload.id,
      amount: payload.amount,
      customTags: payload.customTags.map(tag => ({ id: tag.id })),
      createdBy: payload.createdBy
    } as MarketplaceCustomTagPaymentOrderCreateRequestType

    if (cookies[COOKIE_NAME] && cookies[COOKIE_NAME] !== 'undefined') {
      setClientSecret(cookies[COOKIE_NAME])
      setIsLoading(false)
    }
    else {
      // Create PaymentIntent as soon as the page loads
      const response = await CustomTagHttpClient.setAccessToken(accessToken).CreatePayment(playerSub, paymentPayload)
      const { data, error } = response

      if (data) {
        const { payment } = data
        setClientSecret(payment.clientSecret)
        setCookie(null, COOKIE_NAME, payment.clientSecret)
        setIsLoading(false)
      }
      else if (error && error?.length > 0) {
        const message = isEmpty(error[0].message) ? t("errors.genericContactAdmin") : error[0].message
        toast.error(message)
      }

      return response
    }
  }, [clientSecret])

  const completeIntent = useCallback(() => {
    destroyCookie(null, COOKIE_NAME)
    setClientSecret(undefined)
  }, [])

  const value = useMemo(
    () => ({
      clientSecret,
      setClientSecret,
      completeIntent,
      createCustomTagPayment,
      stripePromise,
      loading
    }),
    [
      clientSecret,
      setClientSecret,
      completeIntent,
      createCustomTagPayment,
      stripePromise,
      loading
    ]
  );

  return (
    <StripePaymentContext.Provider value={value}>
      {children}
    </StripePaymentContext.Provider>
  )
}

const useStripePayment = () => {
  const context = useContext(StripePaymentContext)
  if (!context) {
    throw new Error('PaymentContext must be used within an < PaymentProvider />')
  }

  return context
}

export { useStripePayment, StripePaymentContext }
export default StripePaymentProvider